<template>
  <div class="card">
    <Toast />
    <div class="title">Daftar Katalog</div>

    <DataView
      :value="items"
      :paginator="true"
      :lazy="true"
      :rows="limit"
      :totalRecords="total_records"
      :layout="layout"
      @page="getItems($event)"
    >
      <template #header>
        <div class="p-grid p-fluid p-mb-3">
          <div class="p-col-12 p-md-6 p-lg-3">
            <label for="">Nama Kategori</label> <br />
            <InputText v-model="filter.category" placeholder="Nama Kategori" />
          </div>
          <div class="p-col-12 p-md-6 p-lg-3">
            <label for="">Barcode</label> <br />
            <InputText v-model="filter.code" placeholder="Barcode" />
          </div>
          <div class="p-col-12 p-md-6 p-lg-3">
            <label for="">Kode Kategori</label> <br />
            <InputText v-model="filter.cat_code" placeholder="Kode Kategori" />
          </div>
          <div class="p-col-12 p-md-6 p-lg-3">
            <label for="" style="color: #f8f9fa">-</label> <br />
            <Button
              label="Cari"
              class="p-button-info"
              @click="getItems()"
              v-shortkey="['enter']"
              @shortkey="getItems()"
            />
          </div>
        </div>
        <div class="p-grid p-fluid p-justify-center">
          <div class="p-col-6 p-md-4 p-lg-4" v-if="buttonPrint">
            <Button
              label="Print Receipt"
              class="p-button-danger"
              @click="showDialogPrintStock()"
            />
          </div>
          <div class="p-col-6 p-md-4 p-lg-4" v-if="buttonPrint">
            <Button
              label="Print A4"
              class="p-button-success"
              @click="printPage()"
            />
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col-12 p-md-3 p-lg-3">
            <Checkbox
              v-model="filter.show_not_opname"
              :binary="true"
              :disabled="checkbox_active"
              @change="getItems()"
              style="margin-left: 5px"
            />
            <label
              for=""
              style="padding-left: 5px; margin-bottom: 0px; margin-top: 5px"
              >Belum Opname</label
            >
          </div>
          <div class="p-col-12 p-md-7 p-lg-7"></div>
          <div class="p-col-12 p-md-2 p-lg-2" style="text-align: right">
            <DataViewLayoutOptions v-model="layout" />
          </div>
          <table
            class="table table-responsive"
            style="font-size: 14px"
            v-if="items.length > 0"
          >
            <thead class="thead-light">
              <tr>
                <th>Total Barang</th>
                <th>Total Stok</th>
                <th v-if="total_excess">Excess</th>
                <th v-if="total_deficiency">Deficiency</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h5>{{ total_records | number }}</h5>
                </td>
                <td>{{ all_stock | number }}</td>
                <td v-if="total_excess">{{ total_excess | number }}</td>
                <td v-if="total_deficiency">{{ total_deficiency | number }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="loading.filter"
          class="p-d-flex p-ai-center p-jc-center"
          style="height: 200px"
        >
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          <span style="margin-left: 0.5rem"
            >Mengambil data barang. Mohon tunggu...</span
          >
        </div>
        <div v-else-if="!loading.filter && items.length == 0">
          Data Tidak Ditemukan
        </div>
      </template>

      <template #list="slotProps">
        <div class="p-col-12">
          <div class="product-list-item">
            <img
              :src="
                slotProps.data.thumbnail ? slotProps.data.thumbnail : no_image
              "
              :alt="slotProps.data.name"
            />
            <div class="product-list-detail">
              <div class="product-name">{{ slotProps.data.name }}</div>
              <div class="product-description">{{ slotProps.data.code }}</div>
              <div class="product-description">
                {{ slotProps.data.supplier }}
              </div>
              <div
                class="product-description"
                v-if="slotProps.data.type == 'MATCH'"
                style="font-weight: bold; color: green"
              >
                {{ slotProps.data.type }}
              </div>
              <div
                class="product-description"
                v-if="slotProps.data.type == 'EXCESS'"
                style="font-weight: bold; color: blue"
              >
                {{ slotProps.data.type }}
              </div>
              <div
                class="product-description"
                v-if="slotProps.data.type == 'DEFICIENCY'"
                style="font-weight: bold; color: red"
              >
                {{ slotProps.data.type }}
              </div>
              <div class="product-description">
                {{ slotProps.data.docDate }}
              </div>
              <i class="pi pi-tag product-category-icon"></i>
              <span class="product-category">
                {{ slotProps.data.category }}
              </span>
            </div>
            <div class="product-list-action">
              <span class="product-price">{{
                slotProps.data.price | currency
              }}</span>
              <span class="product-price"
                >Stok: {{ slotProps.data.stock }}</span
              >
              <span class="product-price" v-if="slotProps.data.type != ''"
                >Opname : {{ slotProps.data.temporary_stock }}</span
              >
              <span style="text-align: right">
                <router-link
                  :to="{
                    name: 'catalog-detail',
                    query: { id: slotProps.data.id }
                  }"
                >
                  <Button icon="pi pi-eye"></Button>
                </router-link>
              </span>
            </div>
          </div>
        </div>
      </template>

      <template #grid="slotProps">
        <div class="p-d-flex p-col-12 p-md-3">
          <div class="product-grid-item card">
            <div class="product-grid-item-top">
              <div>
                <i class="pi pi-tag product-category-icon"></i>
                <span class="product-category">{{
                  slotProps.data.category
                }}</span>
              </div>
              <span style="text-align: right">
                Stok: {{ slotProps.data.stock }}
                <p v-if="slotProps.data.type != ''">
                  Opname: {{ slotProps.data.temporary_stock }}
                </p>
              </span>
            </div>
            <div class="product-grid-item-content">
              <div>
                <img
                  :src="
                    slotProps.data.thumbnail
                      ? slotProps.data.thumbnail
                      : no_image
                  "
                  :alt="slotProps.data.name"
                />
              </div>
              <div class="product-name">{{ slotProps.data.name }}</div>
              <div class="product-description">{{ slotProps.data.code }}</div>
              <div class="product-description">
                {{ slotProps.data.supplier }}
              </div>
              <div
                class="product-description"
                v-if="slotProps.data.docDate == '0001-01-01T00:00:00Z'"
              >
                -
              </div>
              <div class="product-description" v-else>
                {{ slotProps.data.docDate.substr(0, 10) | formatDate }}
              </div>
              <div class="product-description">
                <p
                  v-if="slotProps.data.type == 'MATCH'"
                  style="font-weight: bold; color: green"
                >
                  {{ slotProps.data.type }}
                </p>
                <p
                  v-if="slotProps.data.type == 'EXCESS'"
                  style="font-weight: bold; color: blue"
                >
                  {{ slotProps.data.type }}
                </p>
                <p
                  v-if="slotProps.data.type == 'DEFICIENCY'"
                  style="font-weight: bold; color: red"
                >
                  {{ slotProps.data.type }}
                </p>
              </div>
            </div>
            <div class="product-grid-item-bottom">
              <span class="product-price">{{
                slotProps.data.price | currency
              }}</span>
              <router-link
                :to="{
                  name: 'catalog-detail',
                  query: { id: slotProps.data.id }
                }"
              >
                <Button icon="pi pi-eye"></Button>
              </router-link>
            </div>
          </div>
        </div>
      </template>
    </DataView>

    <!-- create dialog print stok -->
    <Dialog
      :visible.sync="dialogPrintStock"
      :style="{ width: '500px' }"
      header="Detail Print Stok Opname"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <h4>Total Page : {{ total_page }}</h4>
      </div>

      <div class="p-field">
        <div style="margin-left: 0.5rem; display: flex; flex-wrap: wrap">
          <div v-for="(item, k) in total_page" :key="k" style="margin: 5px">
            <input
              class="form-check-input checkbox-print"
              type="checkbox"
              :id="`checkbox-${k}`"
              :value="item"
              v-model="selected_pages"
            />
            <label
              class="form-check-label"
              style="margin-left: 0.5rem"
              :for="`checkbox-${k}`"
            >
              Page {{ item }}</label
            >
          </div>
        </div>
      </div>

      <div>
        <Button label="Print" class="btn btn-primary" @click="printStock()">
          <span v-if="loading.loading_print_stock">
            <i class="pi pi-spin pi-spinner" style="font-size: 12px"></i>
          </span>
        </Button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import noImage from '../../assets/images/no-image.png'
import VueNativeSock from 'vue-native-websocket'
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['user', 'isAuthenticated'])
  },
  data() {
    return {
      layout: 'grid',
      loading: {
        filter: false,
        loading_print_stock: false
      },

      filter: {
        category: '',
        supplier: '',
        name: '',
        code: '',
        show_empty_stock: false,
        show_not_opname: true,
        cat_code: ''
      },
      dialogPrintStock: false,

      items: [],
      catalog: [],
      selected_pages: [],
      total_page_stok: 0,
      total_page: 0,

      limit: 8,
      total_records: 0,
      all_stock: 0,
      no_image: noImage,
      total_deficiency: 0,
      total_excess: 0,
      total_stok_done_opname: 0,
      total_barang_done_opname: 0,
      dataPrint: null,
      buttonPrint: false,
      checkbox_active: false,
      socket_enable: false
    }
  },
  mounted() {
    this.getAuthUser()
    if (process.env.VUE_APP_ENABLE_PRINTER) {
      this.initWS()
    }
  },
  methods: {
    getAuthUser() {
      this.getItems()
    },

    initWS() {
      //enable websocket
      this.socket_enable = true
      console.info('Enable websocket printer')
      Vue.use(VueNativeSock, process.env.VUE_APP_PRINTER_URL, {
        reconnection: true, // (Boolean) whether to reconnect automatically (false)
        reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
        reconnectionDelay: 3000 // (Number) how long to initially wait before attempting a new (1000)
      })
    },

    printPage() {
      const routeData = this.$router.resolve({
        name: 'print-catalog-a4',
        query: {
          cat_code: this.filter.cat_code,
          code: this.filter.code,
          outlet_id: this.user.outlet_id,
          show_not_opname: this.filter.show_not_opname
        }
      })
      window.open(routeData.href, '_blank')
    },

    // Fungsi utama getItems
    async getItems(event) {
      let page = 1
      if (event) {
        page = event.page + 1
      }

      this.items = []
      this.total_stok_done_opname = 0
      this.total_barang_done_opname = 0
      this.total_deficiency = 0
      this.total_page_stok = 0
      this.total_excess = 0

      // Panggil sub-fungsi untuk melakukan permintaan HTTP
      await this.fetchDataFromAPI(page)
    },

    // Sub-fungsi untuk melakukan permintaan HTTP ke API
    async fetchDataFromAPI(page) {
      try {
        this.loading.filter = true
        this.checkbox_active = true

        const response = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/stock-opname/outlet-items?category=${this.filter.category}&cat_code=${this.filter.cat_code}&code=${this.filter.code}&show_not_opname=${this.filter.show_not_opname}&page=${page}&limit=${this.limit}`
        )

        if (response.status === 200) {
          this.items = response.data.data ? response.data.data.items : []

          if (this.filter.show_not_opname) {
            this.total_records = response.data.data.total
            await this.getItemsForStock() // Tunggu hingga getItemsForStock selesai
          } else {
            // Panggil sub-fungsi untuk menghitung total stok dan total barang
            await this.calculateTotalStockAndBarang()
            this.total_records = this.data.data.total
            this.all_stock = this.total_stok_done_opname
          }

          this.items = this.items.map(image => {
            // regex untuk mengekstrak ID
            const id = image.thumbnail.match(/[-\w]{25,}/)
            const newUrl =
              id != null ? `https://lh3.google.com/u/0/d/${id}` : ''
            return { ...image, thumbnail: newUrl }
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading.filter = false
        this.checkbox_active = false
        this.buttonPrint =
          (this.filter.cat_code !== '' || this.filter.code !== '') &&
          this.items.length > 0
      }
    },

    // Sub-fungsi untuk menghitung total stok dan total barang yang telah diopname
    async calculateTotalStockAndBarang() {
      for (const item of this.items) {
        this.total_barang_done_opname += 1
        this.total_stok_done_opname += item.stock
        if (item.type === 'EXCESS') {
          this.total_excess += item.difference
        } else if (item.type === 'DEFICIENCY') {
          this.total_deficiency += item.difference
          this.total_page_stok += 1
        }
      }
    },

    async getItemsForStock() {
      this.loading.filter = true

      try {
        const response = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/stock-opname/outlet-items?category=${this.filter.category}&barcode=${this.filter.code}&show_not_opname=${this.filter.show_not_opname}&cat_code=${this.filter.cat_code}`
        )

        if (response.status === 200) {
          this.all_stock = response.data.data.all_stock
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading.filter = false
      }
    },

    showDialogPrintStock() {
      this.dialogPrintStock = true
      this.total_page = Math.ceil(
        this.total_page_stok / process.env.VUE_APP_STOCKOPNAME_PAGINATION
      )
    },

    async loopPrintStok(page) {
      // Get last page from array selected_page
      let footer = false
      if (page === this.selected_pages[this.selected_pages.length - 1]) {
        footer = true
      }

      this.loading.loading_print_stock = true

      try {
        const response = await this.$http.get(
          `${process.env.VUE_APP_API_URL}/stock-opname/report-items?cat_code=${this.filter.cat_code}&limit=${process.env.VUE_APP_STOCKOPNAME_PAGINATION}&page=${page}&show_not_opname=${this.filter.show_not_opname}&footer=${footer}`
        )

        if (response.status === 200) {
          this.dataPrint = response.data.data.print_report_item
          this.$socket.send(this.dataPrint)
          this.dialogPrintStock = false
          this.filter.category = ''
          this.filter.name = ''
          this.socket_enable = false
          this.initWS()
        }
      } catch (error) {
        console.error('Error fetching stock report:', error)
      } finally {
        this.loading.loading_print_stock = false
      }
    },

    printStock() {
      this.loading.loading_print_stock = true
      for (const page of this.selected_pages) {
        if (this.socket_enable) {
          this.loopPrintStok(page)
        }
      }
      this.selected_pages = []
      this.getItems()
    }
  }
}
</script>

<style scoped lang="scss">
.product-name {
  font-size: 1.5rem;
  font-weight: 700;
}

.product-description {
  margin: 0 0 1rem 0;
}

.product-category-icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.product-category {
  font-weight: 600;
  vertical-align: middle;
}

/deep/ .product-list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;

  img {
    width: 150px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
  }

  .product-list-detail {
    flex: 1 1 0;
  }

  .p-rating {
    margin: 0 0 0.5rem 0;
  }

  .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    align-self: flex-end;
  }

  .product-list-action {
    display: flex;
    flex-direction: column;
  }

  .p-button {
    margin-bottom: 0.5rem;
  }
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}

/deep/ .product-grid-item {
  margin: 0.5em;
  border: 1px solid #dee2e6;

  .product-grid-item-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 75%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
  }

  .product-grid-item-content {
    text-align: center;
  }

  .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: 576px) {
  .product-list-item {
    flex-direction: column;
    align-items: center;

    img {
      width: 75%;
      margin: 2rem 0;
    }

    .product-list-detail {
      text-align: center;
    }

    .product-price {
      align-self: center;
    }

    .product-list-action {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}
</style>
